<template>
  <div>
    <web v-if="!isMobile" />
    <mobile v-if="isMobile" />
  </div>
</template>

<script>
import web from './applicationWeb'
import mobile from './mobile'
import { judgeMobile } from '../../../utils/common'
export default {
  components: {
    web,
    mobile
  },
  data() {
    return {
      isMobile: false
    }
  },
  created() {
    this.isMobile = judgeMobile()
  }
}
</script>
