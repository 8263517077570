<template>
  <div id="header" class="web_container">
    <div class="header">
      <div class="wrapper">
        <img src="../../../static/img/pc/logo.png">
        <div class="right_side">
          <div class="link" @click="goHeader">首页</div>
          <div class="link" @click="goService">服务介绍</div>
          <div class="link" @click="goConcat">联系我们</div>
          <div class="go_official" onclick="window.open('http://www.lian-ou.com/')">进入莲藕官网<img src="../../../static/img/pc/path.png"></div>
        </div>
      </div>
    </div>
    <div class="top">
      <img class="top_img" src="../../../static/img/pc/header.png">
      <div class="top_btn" @click="goConcat" />
    </div>
    <div id="service" />
    <img class="service_img" src="../../../static/img/pc/service.png">
    <img class="service_img" src="../../../static/img/pc/flow.png">
    <div class="show_more" @click="goConcat">了解更多 <img src="../../../static/img/pc/path.png"></div>
    <img class="hospotal_img" src="../../../static/img/pc/hospital.png">
    <div class="hospital">
      <div class="swiper_container">
        <img class="arrow prev" src="../../../static/img/pc/arrow_left_small.png" @click="hospitalPrev">
        <div class="gallery">
          <ul class="images" :style="{ marginLeft: hospitalLeft + 'px'}">
            <li v-for="item in hospitalList" :key="item.id">
              <img :class="hospitalIndex == item.id? 'active_img': ''" :src="item.src">
              <div class="title">{{ item.title }}</div>
            </li>
          </ul>
        </div>
        <img class="arrow next" src="../../../static/img/pc/arrow_right_small.png" @click="hospitalNext">
      </div>
    </div>
    <div class="pagination">
      <img class="arrow prev" src="../../../static/img/pc/arrow_left_small.png" @click="hospitalPrev">
      <div class="label">{{ hospitalIndex }}/6</div>
      <img class="arrow next" src="../../../static/img/pc/arrow_right_small.png" @click="hospitalNext">
    </div>
    <div class="tips">更多功能持续更新中…</div>
    <div class="show_more" @click="goConcat">了解更多 <img src="../../../static/img/pc/path.png"></div>
    <div class="case">
      <img class="case_title" src="../../../static/img/pc/case.png">
      <div class="swiper_container">
        <img class="arrow prev" src="../../../static/img/pc/arrow_left.png" @click="casePrev">
        <div class="gallery">
          <ul class="images" :style="{ marginLeft: caseMargninLeft + 'px'}">
            <li v-for="item in caseList" :key="item.id"><img :class="caseIndex == item.id? 'active_img': ''" :src="item.src"></li>
          </ul>
        </div>
        <img class="arrow next" src="../../../static/img/pc/arrow_right.png" @click="caseNext">
      </div>
    </div>
    <img class="advantage_img" src="../../../static/img/pc/advantage.png">
    <div class="case">
      <img class="case_title" src="../../../static/img/pc/quality.png">
      <div class="swiper_container">
        <img class="arrow prev" src="../../../static/img/pc/arrow_left.png" @click="certificatePrev">
        <div class="gallery">
          <ul class="images" :style="{ marginLeft: certificateLeft + 'px'}">
            <li v-for="item in certificateList" :key="item.id"><img :class="certificateIndex == item.id? 'active_img': ''" :src="item.src"></li>
          </ul>
        </div>
        <img class="arrow next" src="../../../static/img/pc/arrow_right.png" @click="certificateNext">
      </div>
    </div>

    <div class="trend_wrapper">
      <img class="trend_img" src="../../../static/img/pc/trend.png">
      <div class="news_header" onclick="window.open('http://www.gov.cn/gongbao/content/2019/content_5358684.htm')">
        <img src="../../../static/img/trend/trend-1.png" alt="">
        <div class="content">
          <div class="title">卫生健康委 中医药局关于印发互联网诊疗管理办法（试行）等3个文件的通知</div>
          <div class="content1">各省、自治区、直辖市及新疆生产建设兵团卫生计生委、中医药管理局：</div>
          <div class="content2">为贯彻落实《国务院办公厅关于促进“互联网+医疗健康”发展的意见》有关要求，进一步规范互联网诊疗行为，发挥远程医疗服务积极作用，提高医疗服务效率，保证医疗质量和医疗安全，国家卫生健康委员会和国家中医药管理局组织制定了《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》，现印发给你们，请遵照执行。
            卫生健康委</div>
          <div class="content3">中 医 药 局</div>
          <div class="content3">2018年7月17日</div>
        </div>
      </div>
      <div class="news_list">
        <div class="news_item">
          <img src="../../../static/img/trend/trend-2.png" alt="">
          <div class="item_info" onclick="window.open('http://www.gov.cn/zhengce/content/2018-04/28/content_5286645.htm')">
            <div class="item_date">2018-04-25</div>
            <div class="item_title">国务院办公厅关于促进“互联网+医疗健康”发展的意见</div>
          </div>
        </div>
        <div class="news_item">
          <img src="../../../static/img/trend/trend-3.png" alt="">
          <div class="item_info" onclick="window.open('http://wsjkw.gd.gov.cn/zwgk_gsgg/content/post_2997655.html')">
            <div class="item_date">2020-05-18</div>
            <div class="item_title">广东省卫生健康委办公室关于进一步规范广东省互联网医疗服务监管平台接入工作的通知+医疗健康”发展的意见</div>
          </div>
        </div>
        <div class="news_item">
          <img src="../../../static/img/trend/trend-4.png" alt="">
          <div class="item_info" onclick="window.open('http://wsjkw.sc.gov.cn/scwsjkw/zcwj11/2020/12/31/e271583396bf4a918404fd8588587c2b.shtml')">
            <div class="item_date">2020-12-31</div>
            <div class="item_title">关于印发《四川省互联网诊疗管理规范（试行）》的通知+医疗健康”发展的意见</div>
          </div>
        </div>
      </div>
      <div id="concat" class="show_more" @click="goConcat">了解更多 <img src="../../../static/img/pc/path.png"></div>
    </div>
    <div class="concat_wrapper">
      <img class="concat_img" src="../../../static/img/pc/concat.png">
      <div class="content">
        <div class="code">
          <img src="../../../static/img/互联网医院建设-商务.png">
          <div>黄经理</div>
          <div>18826457568</div>
        </div>
        <!-- <div class="code">
          <img src="../../../static/img/code/xiaotong.png">
          <div>肖经理</div>
          <div>13928814259</div>
        </div> -->
        <div class="form">
          <div class="label">留下您的联系方式，服务经理将很快联系您</div>
          <input v-model="form.userName" class="name" type="text" placeholder="您的姓名">
          <input v-model="form.phone" class="mobile" type="text" placeholder="手机号码">
          <img src="../../../static/img/pc/submit.png" @click="submit">
        </div>
      </div>
    </div>
    <img class="footer_img" src="../../../static/img/pc/footer.png">
  </div>
</template>

<script>

export default {
  data() {
    return {
      caseList: [
        {
          id: 1,
          src: require('../../../static/img/case/case1.png')
        },
        {
          id: 2,
          src: require('../../../static/img/case/case2.jpg')
        },
        {
          id: 3,
          src: require('../../../static/img/case/case3.jpg')
        },
        {
          id: 4,
          src: require('../../../static/img/case/case4.jpg')
        },
        {
          id: 5,
          src: require('../../../static/img/case/case5.png')
        },
        {
          id: 6,
          src: require('../../../static/img/case/case6.jpg')
        }
      ],
      caseIndex: 2,
      caseMargninLeft: 0,
      certificateList: [
        {
          id: 1,
          src: require('../../../static/img/certificate/高新技术企业证书.jpg')
        },
        {
          id: 2,
          src: require('../../../static/img/certificate/三级等保.jpg')
        },
        {
          id: 3,
          src: require('../../../static/img/certificate/营业执照(1).jpg')
        }
      ],
      certificateIndex: 2,
      certificateLeft: 0,
      hospitalList: [
        {
          id: 1,
          title: '医生品牌',
          src: require('../../../static/img/tabImage/1.jpg')
        },
        {
          id: 2,
          title: '在线咨询',
          src: require('../../../static/img/tabImage/2.jpg')
        },
        {
          id: 3,
          title: '知识付费',
          src: require('../../../static/img/tabImage/3.jpg')
        },
        {
          id: 4,
          title: '医生直播',
          src: require('../../../static/img/tabImage/4.png')
        },
        {
          id: 5,
          title: '开方购药',
          src: require('../../../static/img/tabImage/5.png')
        },
        {
          id: 6,
          title: '患者打赏',
          src: require('../../../static/img/tabImage/6.jpg')
        }
      ],
      hospitalIndex: 2,
      hospitalLeft: 0,
      form: {
        userName: '',
        phone: ''
      }
    }
  },
  methods: {
    casePrev() {
      if (this.caseIndex === 1) return
      this.caseIndex--
      this.caseMargninLeft += 371
    },
    caseNext() {
      if (this.caseIndex === 6) return
      this.caseIndex++
      this.caseMargninLeft -= 371
    },
    certificatePrev() {
      if (this.certificateIndex === 1) return
      this.certificateIndex--
      this.certificateLeft += 371
    },
    certificateNext() {
      if (this.certificateIndex === 3) return
      this.certificateIndex++
      this.certificateLeft -= 371
    },
    hospitalPrev() {
      if (this.hospitalIndex === 1) return
      this.hospitalIndex--
      this.hospitalLeft += 343
    },
    hospitalNext() {
      if (this.hospitalIndex === 6) return
      this.hospitalIndex++
      this.hospitalLeft -= 343
    },
    submit() {
      if (!this.form.userName) {
        alert('请输入您的姓名')
        return
      }
      if (!this.form.phone) {
        alert('请输入手机号')
        return
      }
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!myreg.test(this.form.phone)) {
        alert('手机号格式不正确')
        return
      }
      const { userName, phone } = { ...this.form }
      this.$api.AddInternetHospitalMessage({
        userName,
        phone
      }).then(res => {
        if (res.code == 1) {
          alert('提交成功')
          this.form.userName = ''
          this.form.phone = ''
        }
      })
    },
    goHeader() {
      document.querySelector('#header').scrollIntoView({ behavior: 'smooth' })
    },
    goService() {
      document.querySelector('#service').scrollIntoView({ behavior: 'smooth' })
    },
    goConcat() {
      document.querySelector('#concat').scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scpoed>
  .web_container {
    width: 100%;
    text-align: left;
    font-size: 0px;
    font-family: PingFangSC-Regular, PingFang SC;
    padding-top: 90px;
    .header {
      width: 100%;
      margin: 0 auto;
      position: fixed;
      top: 0;
      background: #fff;
      z-index: 10000;
      .wrapper {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        img {
          width: 140px;
          height: 36px;
        }
        .right_side {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          color: rgba(45, 43, 94, 1);
          .link {
            margin-left: 50px;
            cursor: pointer;
            position: relative;
            height: 90px;
            line-height: 90px;
            box-sizing: border-box;
            &:hover{
              border-bottom: 5px solid rgb(128, 122, 244);
              transition: border .2s;
            }
          }
          .go_official {
            border-radius: 4px;
            padding: 10px;
            border: 1px solid #5D5D7A;
            display: flex;
            align-items: center;
            margin-left: 50px;
            cursor: pointer;
            img {
              width: 6px;
              height: 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .top {
      width: 100%;
      position: relative;
      .top_img {
        width: 100%;
      }
      .top_btn {
        position: absolute;
        top: 73%;
        width: 100%;
        height: 60px;
        cursor: pointer;
      }
    }
    .service_img {
      width: 1200px;
      margin: 100px auto 0 auto;
      display: block;
    }
    .hospotal_img {
      width: 1200px;
      margin: 50px auto 0 auto;
      display: block;
    }
    .hospital {
      width: 1200px;
      height: 807px;
      text-align: center;
      background: #F1F1FE;
      margin: 0 auto;
      .case_title {
        width: 366px;
        height: 53px;
        padding-top: 110px;
      }
      .swiper_container {
        position: relative;
        width: 1200px;
        margin: 30px auto;
        padding: 40px 0;
        .arrow {
          position: absolute;
          top: 354px;
          display: block;
          height: 42px;
          width: 24px;
        }

        .arrow:focus {
          outline: none;
        }

        .arrow:hover {
          cursor: pointer;
        }

        .prev {
          left: 40px;
          z-index: 200;
        }

        .next {
          right: 40px;
        }
        .gallery {
          width: 992px;
          margin: 0 auto;
          overflow: hidden;
          ul {
            text-align: left;
            width: 9999px;
            padding: 0;
            list-style: none;
            transition: margin-left 250ms;
            font-size: 0;
            li {
              width: 306px;
              display: inline-block;
              margin-right: 37px;
              text-align: center;
              img {
                width: 306px;
                height: 674px;
              }
              .title {
                font-size: 18px;
                font-weight: 400;
                color: #5D5D7A;
                margin-top: 34px;
              }
            }
          }
        }
      }
    }
    .tips {
      font-size: 16px;
      font-weight: 400;
      color: #5D5D7A;
      text-align: center;
      margin-bottom: -20px;
    }
    .pagination {
      width: 161px;
      font-size: 22px;
      font-weight: 400;
      color: #999;
      margin: 35px auto;
      display: flex;
      align-items: center;
      .label {
        margin: 0 51px;
      }
      img {
        width: 12px;
        height: 23px;
        cursor: pointer;
      }
    }
    .advantage_img {
      width: 1200px;
      margin: 100px auto;
      display: block;
    }
    @media screen and (max-width: 1250px) {
      .service_img, .hospotal_img, .advantage_img {
        width: 90%;
      }
      .swiper_container {
        transform: scale(0.8);
      }
      .concat_wrapper {
        width: 95% !important;
        padding: 100px 0;
      }
    }
    .show_more {
      width: 122px;
      height: 31px;
      border-radius: 18px;
      border: 1px solid #5D5D7A;
      font-size: 14px;
      font-weight: 400;
      color: #5D5D7A;
      line-height: 31px;
      text-align: center;
      cursor: pointer;
      margin: 33px auto;
      img {
        width: 7px;
        height: 11px;
      }
    }
    .case {
      width: 100%;
      height: 604px;
      text-align: center;
      background: linear-gradient(133deg, #A250FF 0%, #8A4DFF 100%);
      .case_title {
        width: 366px;
        height: 53px;
        margin-top: 110px;
      }
      .swiper_container {
        position: relative;
        width: 1200px;
        margin: 50px auto;
        .arrow {
          position: absolute;
          top: 100px;
          display: block;
          height: 63px;
          width: 34px;
        }

        .arrow:focus {
          outline: none;
        }

        .arrow:hover {
          cursor: pointer;
        }

        .prev {
          left: 7px;
          z-index: 200;
        }

        .next {
          right: 7px;
        }
        .gallery {
          width: 1113px;
          margin: 0 auto;
          overflow: hidden;
          ul {
            text-align: left;
            height: 278px;
            width: 9999px;
            padding: 0;
            list-style: none;
            transition: margin-left 250ms;
            font-size: 0;
            li {
              width: 371px;
              height: 278px;
              display: inline-block;
              position: relative;
              img {
                width: 280px;
                height: 205px;
                opacity: .5;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            .active_img {
              width: 371px;
              height: 278px;
              opacity: 1;
            }
          }
        }
      }
    }
    .trend_wrapper {
      max-width: 1200px;
      margin: 100px auto;
      text-align: center;
      .trend_img {
        width: 406px;
        height: 53px;
      }
      .news_header {
        display: flex;
        margin-top: 50px;
        cursor: pointer;
        img {
          width: 292px;
          height: 216px;
        }
        .content {
          text-align: left;
          background: #F6F6FF;
          font-size: 14px;
          font-weight: 400;
          color: #5D5D7A;
          padding: 25px;
          .title {
            font-size: 20px;
            font-weight: 600;
            color: #2D2B5E;
          }
          .content1 {
            margin-top: 10px;
          }
          .content2 {
            line-height: 22px;
            text-indent: 2em;
          }
          .content3 {
            text-align: right;
          }
        }
      }
      .news_list {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        font-size: 0;
        .news_item {
          width: 386px;
          cursor: pointer;
          img {
            width: 386px;
            height: 234px;
            border-radius: 0;
          }
          .item_info {
            background: #F6F6FF;
            text-align: left;
            padding: 20px;
            .item_date {
              font-size: 14px;
              font-weight: 400;
              color: #5D5D7A;
            }
            .item_title {
              font-size: 16px;
              font-weight: 600;
              color: #2D2B5E;
              margin-top: 10px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    .concat_wrapper {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      width: 1200px;
      height: 538px;
      box-shadow: 0px 5px 28px 0px rgba(36,32,96,0.14);
      border-radius: 20px;
      .concat_img {
        width: 549px;
        height: 83px;
        margin-top: -41px;
      }
      .content {
        display: flex;
        justify-content: space-around;
        padding: 100px;
        .code {
          font-size: 25px;
          font-weight: 400;
          color: #2D2B5E;
          img {
            width: 179px;
            height: 179px;
          }
        }
        .form {
          width: 458px;
          height: 297px;
          background: #FBFBFF;
          padding: 50px;
          box-sizing: border-box;
          text-align: center;
          input {
            width: 338px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #D6D6DE;
            background: #FBFBFF;
            text-align: center;
            font-size: 16px;
          }
          input:focus {
            outline: 1px solid #51c9ff;
          }
          .name {
            margin-top: 20px;
          }
          .mobile {
            margin-top: 10px;
          }
          .label {
            font-size: 16px;
            font-weight: 400;
            color: #2D2B5E;
          }
          img {
            width: 205px;
            height: 50px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .footer_img {
      width: 100%;
      margin-top: 100px;
    }
  }
</style>
