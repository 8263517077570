<template>
  <div class="mobile_container">
    <div class="image_list">
      <div v-for="item in heaserImgList" :key="item.id" class="relative">
        <div v-if="item.id != 3" class="advisory" @click="gotoBottom(-50)" />
        <img :src="item.url">
      </div>
    </div>
    <div class="hospital">
      <div class="swipe_box flex">
        <div class="swipe_left">
          <div
            v-for="(item, index) in swipeLeft"
            :key="item.name"
            class="relative"
          >
            <button
              :class="{ active_btn: swiperIndex === index }"
              @click="handleClickButton(index)"
            >
              {{ item.name }}
            </button>
            <div v-if="swiperIndex === index" class="triangle" />
          </div>
        </div>

        <div class="swipe_right">
          <img :src="tabImageList[swiperIndex]">
        </div>
      </div>
      <div class="more_label">更多功能持续更新中</div>
      <div class="show_more" @click="gotoBottom(-50)">了解更多</div>
    </div>
    <img src="../../../static/img/success_header.png">
    <swiper effect="cube" :initial-slide="1" @slideChange="onSlideChange1">
      <swiper-slide :style="{marginLeft: swiper1Begin? '.2rem': ''}"><img
        src="../../../static/img/case/case1.png"
        alt=""
      ></swiper-slide>
      <swiper-slide><img
        src="../../../static/img/case/case2.jpg"
        alt=""
      ></swiper-slide>
      <swiper-slide><img
        src="../../../static/img/case/case3.jpg"
        alt=""
      ></swiper-slide>
      <swiper-slide><img
        src="../../../static/img/case/case4.jpg"
        alt=""
      ></swiper-slide>
      <swiper-slide><img
        src="../../../static/img/case/case5.png"
        alt=""
      ></swiper-slide>
      <swiper-slide><img
        src="../../../static/img/case/case6.jpg"
        alt=""
      ></swiper-slide>
    </swiper>
    <img src="../../../static/img/trend.png">
    <div class="trend">
      <div class="trend_wrapper">
        <div class="header" onclick="window.open('http://www.gov.cn/gongbao/content/2019/content_5358684.htm')">
          <img src="../../../static/img/trend/trend-1.png" alt="">
          <div class="title">卫生健康委 中医药局关于印发互联网诊疗管理办法（试行）等3个文件的通知</div>
          <div class="content">
            <div class="content1">各省、自治区、直辖市及新疆生产建设兵团卫生计生委、中医药管理局：</div>
            <div class="content2">为贯彻落实《国务院办公厅关于促进“互联网+医疗健康”发展的意见》有关要求，进一步规范互联网诊疗行为，发挥远程医疗服务积极作用，提高医疗服务效率，保证医疗质量和医疗安全，国家卫生健康委员会和国家中医药管理局组织制定了《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》，现印发给你们，请遵照执行。
              卫生健康委</div>
            <div class="content3">中 医 药 局</div>
            <div class="content3">2018年7月17日</div>
          </div>
        </div>
        <div class="news">
          <div class="news_item">
            <div class="item" onclick="window.open('http://www.gov.cn/zhengce/content/2018-04/28/content_5286645.htm')">
              <img src="../../../static/img/trend/trend-2.png" alt="">
              <div class="item_info">
                <div class="item_title">国务院办公厅关于促进“互联网+医疗健康”发展的意见</div>
                <div class="item_date">2018-04-25</div>
              </div>
            </div>
            <div class="item" onclick="window.open('http://wsjkw.gd.gov.cn/zwgk_gsgg/content/post_2997655.html')">
              <img src="../../../static/img/trend/trend-3.png" alt="">
              <div class="item_info">
                <div class="item_title">广东省卫生健康委办公室关于进一步规范广东省互联网医疗服务监管平台接入工作的通知</div>
                <div class="item_date">2020-05-18</div>
              </div>
            </div>
            <div class="item" onclick="window.open('http://wsjkw.sc.gov.cn/scwsjkw/zcwj11/2020/12/31/e271583396bf4a918404fd8588587c2b.shtml')">
              <img src="../../../static/img/trend/trend-4.png" alt="">
              <div class="item_info">
                <div class="item_title">关于印发《四川省互联网诊疗管理规范（试行）》的通知</div>
                <div class="item_date">2020-12-31</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show_more" style="margin-top: .22rem" @click="gotoBottom(-50)">了解更多</div>
    </div>
    <img src="../../../static/img/修改备份8.png">
    <swiper effect="cube" :initial-slide="1" style="background: linear-gradient(rgb(78, 44, 188), rgb(100, 52, 224))" @slideChange="onSlideChange2">
      <swiper-slide :style="{marginLeft: swiper2Begin? '.2rem': ''}"><img src="../../../static/img/certificate/高新技术企业证书.jpg" alt=""></swiper-slide>
      <swiper-slide><img src="../../../static/img/certificate/三级等保.jpg" alt=""></swiper-slide>
      <swiper-slide><img src="../../../static/img/certificate/营业执照(1).jpg" alt=""></swiper-slide>
    </swiper>
    <div class="last_box_bg">
      <div class="last_box">
        <img class="absolute_img" src="../../../static/img/mob-7.png">
        <div class="flex_center">
          <div v-for="item in codeList" :key="item.id" class="code_box">
            <img :src="item.code" alt="" class="code_img">
            <p class="">{{ item.name }}</p>
            <p class="mobile">{{ item.mobile }}</p>
            <a class="call_mobile" :href="'tel:'+item.mobile">
              <img src="../../../static/img/call.png" alt="">
            </a>
          </div>

        </div>
        <img class="line_image" src="../../../static/img/直线.png" alt="">
        <div class="fill_form">
          <p class="fill_tip">留下您的联系方式，服务经理将很快联系您</p>
          <input v-model="form.userName" type="text" placeholder="您的姓名" @focus="scrollBottom">
          <input v-model="form.phone" type="tel" placeholder="手机号码" @focus="scrollBottom">
        </div>
        <div id="submit_btn" class="submit_btn" @click="submit" />
      </div>
    </div>
    <img class="footer_bottom" src="../../../static/img/end.png" alt="">
    <img src="https://pic.lian-ou.com/H5/web/single-page-project/application-4.png" class="fix_btn" alt="" @click="gotoBottom(-50)">
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      heaserImgList: [
        { url: require('../../../static/img/mobile-1.png'), id: 1 },
        { url: require('../../../static/img/mobile-2.png'), id: 2 },
        { url: require('../../../static/img/mobile-3.png'), id: 3 }
      ],
      swiperIndex: 0,
      swipeLeft: [
        { name: '医生品牌' },
        { name: '在线咨询' },
        { name: '知识付费' },
        { name: '医生直播' },
        { name: '开方购药' },
        { name: '患者打赏' }
      ],
      tabImageList: [
        require('../../../static/img/tabImage/1.jpg'),
        require('../../../static/img/tabImage/2.jpg'),
        require('../../../static/img/tabImage/3.jpg'),
        require('../../../static/img/tabImage/4.png'),
        require('../../../static/img/tabImage/5.png'),
        require('../../../static/img/tabImage/6.jpg')
      ],
      swiper1Begin: true,
      swiper2Begin: true,
      form: {
        userName: '',
        phone: ''
      },
      codeList: [
        {
          code: require('../../../static/img/互联网医院建设-商务.png'),
          id: 'weiyun',
          mobile: `18826457568`,
          name: '黄经理'
        }
        // {
        //   code: require('../../../static/img/code/xiaotong.png'),
        //   id: 'xiaotong',
        //   mobile: 13928814259,
        //   name: '肖经理'
        // },
        // {
        //   code: require('../../../static/img/code/jifu.png'),
        //   id: 'jifu',
        //   mobile: 15920570383,
        //   name: '王经理'
        // }
      ]
    }
  },
  created() {
    document.title = '申办互联网医院'
  },
  methods: {
    gotoBottom(num) {
      this.$nextTick(() => {
        const t = document.querySelector('.last_box').offsetTop + num
        window.scroll({ top: t, left: 0, behavior: 'smooth' })
      })
    },
    handleClickButton(index) {
      this.swiperIndex = index
    },
    onSlideChange1(swiper) {
      this.swiper1Begin = swiper.isBeginning
    },
    onSlideChange2(swiper) {
      this.swiper2Begin = swiper.isBeginning
    },
    scrollBottom() {
      setTimeout(() => {
        this.gotoBottom(200)
      }, 600)
    },
    submit() {
      if (!this.form.userName) {
        alert('请输入您的姓名')
        return
      }
      if (!this.form.phone) {
        alert('请输入手机号')
        return
      }
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!myreg.test(this.form.phone)) {
        alert('手机号格式不正确')
        return
      }
      const { userName, phone } = { ...this.form }
      this.$api.AddInternetHospitalMessage({
        userName,
        phone
      }).then(res => {
        if (res.code == 1) {
          alert('提交成功')
          this.form.userName = ''
          this.form.phone = ''
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@function torem($px){ //$px为需要转换的字号
    @return $px / 100 + rem; //100px为根字体大小
}
.mobile_container {
  font-size: 0;
  img {
    width: 100%;
    margin-top: -1px;
  }
  .image_list {
    font-size: 0;
    .advisory {
      position: absolute;
      bottom: torem(60);
      left: 0;
      width: 100%;
      height: torem(50);
    }
  }
  .hospital {
    background: linear-gradient(rgb(119, 24, 203), rgb(91, 23, 174));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: torem(10) 0;
    margin-top: -1px;
    .swipe_box {
      justify-content: center;
      .swipe_left {
        width: torem(94);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #8f47df;
        border-radius: 8px;
        button {
          display: block;
          width: 100%;
          background: rgba(0, 0, 0, 0);
          border: 0;
          height: torem(60);
          font-size: torem(15);
          color: #fff;
          outline: none;
        }
        .active_btn {
          background: linear-gradient(121deg, #ee6387 0%, #d83397 100%);
          border-radius: 8px;
          width: torem(94);
          height: torem(45);
        }
        .triangle {
          width: 0;
          height: 0;
          border: 10px solid;
          border-color: transparent #fff transparent transparent;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(100%, -50%);
        }
      }
      .swipe_right {
        margin-left: torem(15);
        width: torem(200);
        padding: torem(13) torem(13) torem(20);
        width: torem(215);
        height: torem(446);
        background: #ffffff;
        border-radius: 7px;
        box-sizing: border-box;
        img {
          width: torem(191);
          // height: 306px;
          height: auto;
        }
      }
    }
    .more_label {
      font-size: torem(13);
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-top: torem(20);
    }
  }
  .relative {
    position: relative;
  }

  .swiper-container {
    height: torem(229);
    background: linear-gradient(rgb(86, 23, 169), rgb(71, 22, 154));
  }

  .swiper-slide-prev {
    margin-top: torem(40);
    margin-left: torem(24);
    opacity: .5;
    img {
      height: torem(158) !important;
    }
  }

  .swiper-slide-next {
    margin-right: torem(24);
    margin-top: torem(40);
    opacity: .5;
    img {
      height: torem(158) !important;
    }
  }

  .swiper-slide-active {
    width: torem(287) !important;
    height: torem(210);
    padding: torem(21);
    img {
      width: torem(287) !important;
      height: torem(210);
    }
  }

  .show_more {
    width: torem(105);
    height: torem(31);
    border-radius: 18px;
    opacity: 0.6;
    border: 1px solid #ffffff;
    font-size: torem(14);
    font-weight: 400;
    color: #ffffff;
    line-height: torem(31);
    text-align: center;
    margin: 0 auto;
    margin-top: torem(16);
  }

  .trend {
    background-image: linear-gradient(rgb(67, 21, 149), rgb(49, 25, 136));
    .trend_wrapper {
      width: torem(350);
      margin: 0 auto;
      background: #fff;
      border-radius: 6px;
      .header {
        padding: torem(17);
        .title {
          font-size: torem(13);
          font-weight: 600;
          color: #2D2B5E;
          margin-top: torem(10);
          text-align: left;
        }
        .content {
          font-size: torem(11);
          font-weight: 400;
          color: #5D5D7A;
          margin-top: torem(5);
          text-align: left;
        }
        .content2 {
          text-indent: 2em;
        }
        .content3 {
          text-align: right;
        }
      }
      .news {
        text-align: left;
        .news_item {
          padding: torem(17);
          .item {
            display: flex;
            justify-content: space-between;
            &:not(:first-child) {
              margin-top: torem(22);
            }
            img {
              width: torem(142);
              height: torem(82);
              flex: 0 0 auto;
            }
            .item_info {
              margin-left: torem(11);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item_title {
                font-size: torem(13);
                font-weight: 600;
                color: #2D2B5E;
              }
              .item_date {
                font-size: torem(10);
                font-weight: 400;
                color: #5D5D7A;
              }
            }
          }
        }
      }
    }
  }
  .last_box_bg{
    background-image: url('../../../static/img/bgbg-2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: torem(46);
    margin-top: -1px;
  }
  .last_box{
    position: relative;
    width: torem(350);
    height: torem(580);
    box-sizing: border-box;
    padding-top: torem(55);
    margin: 0 auto ;
    background: #FFFFFF;
    border-radius: 10px;
    // margin-top: 26px;
    .absolute_img{
      position:absolute;
      width: torem(275);
      height: torem(42);
      left: 50%;
      top: torem(-21);
      transform:translateX(-50%);
    }
    .code_img{
      width: torem(130);
      height: torem(130);
    }
    // .code_box:first-child{
    //   margin-right: torem(34);

    // }
    .code_box{
      font-size: torem(16);
      font-family: PingFangSC-Regular;
      .mobile{
        margin-top: torem(4);
      }
    }

  }
  .call_mobile{
    img{
      margin-top: torem(8);
      width: torem(105);
      height: torem(31);
    }
  }
  .line_image{
    width: torem(315);
    margin: 0 auto;
    margin-top: torem(20);
  }
  .fill_form{
    font-size: torem(15);
    text-align: center;
    margin-top: torem(22) ;
    .fill_tip{
      margin-bottom: torem(16);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #333333;
    }
    input{
      width: torem(306);
      height: torem(43);
      border: 1px solid #C1C1C1;
      margin-bottom: torem(11);
      box-sizing: border-box;
      padding: 0 torem(20);
      text-align: center;
    }
  }
  .submit_btn{
    background-image: url('../../../static/img/submit.png');
    background-size: 100% 100% ;
    background-repeat: no-repeat;
    width: torem(256);
    height: torem(60);
    position: absolute;
    bottom: torem(120);
    left: 50%;
    transform: translateX(-50%);
  }
  .fix_btn{
    width: 100%;
    position: fixed;
    left: 0;
    height: auto;
    bottom: 0;
    min-width: torem(375);
    max-width: torem(450);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
  }
  .footer_bottom{
    width: 100%;
    margin: 0 auto;
    background-size: red;
  }
}
</style>
